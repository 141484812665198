<template>
    <div class="registration-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('invention.registration.title[0]') }}
        </div>
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('invention.registration.title[1]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.qualifications" />
            </div>
        </div>
        <Anchor :id="'judge-method'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('invention.registration.title[2]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.judgeMethod" />
            </div>
        </div>
        <Anchor :id="'schedule'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('invention.registration.title[3]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.schedule" />
            </div>
        </div>
        <Anchor :id="'prize'" />
        <div class="sub-block prize">
            <div class="sub-title">
                {{ $t('invention.registration.title[4]') }}
            </div>
            <div class="block-content font-size-medium">
                <div>
                    {{ $t('invention.registration.content') }}
                </div>
                <br>
                <div class="prize-block">
                    <div class="panels">
                        <v-expansion-panels
                            multiple
                            accordion
                        >
                            <v-expansion-panel
                                v-for="(item, i) in prizeList"
                                :key="i"
                                class="panel-item"
                            >
                                <v-expansion-panel-header
                                    class="prize-title"
                                >
                                    {{ item.title }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                    class="prize-content"
                                >
                                    {{ item.content }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-block certificate">
            <div class="sub-title">
                {{ $t('invention.registration.title[5]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.certificate" />
            </div>
        </div>
        <div class="sub-block contact">
            <div class="sub-title">
                {{ $t('invention.registration.title[6]') }}
            </div>
            <div class="block-content">
                <div class="color-gray remark">
                    <content-renderer :content="content.contact" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentRenderer from '../ContentRenderer.vue';

export default {
    components: {
        ContentRenderer,
    },
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
        prizeList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss">
.registration-block {
    .remark {
        font-size: 16px;
        line-height: 2;
        text-align: left;

        a {
            text-decoration: none;
        }
    }

    .prize {
        .block-content {
            font-size: 21px;
        }
    }

    .prize-block {
        .prize-title {
            font-weight: 700;
            font-size: 21px;
            line-height: 1;
            letter-spacing: 0.1em;
            color: $color-blue;
        }

        .prize-content {
            white-space: pre-line;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 0.1em;
            color: $color-gray;
        }

        .v-expansion-panel-header__icon {
            position: absolute;
            right: 0;
            .v-icon {
                color: $color-orange !important;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .registration-block {
        .remark {
            font-size: 12px;
        }

        .prize {
            .block-content {
                font-size: 16px;
            }
        }

        .prize-block {
            .prize-title {
                font-size: 16px;
            }

            .prize-content {
                font-size: 14px;
            }
        }
    }
}
</style>
