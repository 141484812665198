<template>
    <div class="explain-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('invention.explain.title[0]') }}
        </div>
        <div class="video">
            <iframe
                v-if="content"
                class="video-frame"
                :src="getVideo(content.video)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('invention.explain.title[1]') }}
            </div>
            <div class="block-content">
                <content-renderer :content="content.purpose" />
            </div>
        </div>
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('invention.explain.title[2]') }}
                <div class="title-tips">
                    {{ $t('invention.explain.tips') }}
                </div>
            </div>
            <div class="block-content theme">
                <img
                    class="theme-image"
                    src="../../assets/invention/theme.jpg"
                    alt=""
                >
            </div>
        </div>
    </div>
</template>

<script>
import ContentRenderer from '../ContentRenderer.vue';

export default {
    components: {
        ContentRenderer,
    },
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        getVideo(value) {
            if (typeof value !== 'undefined' && value.includes('watch?v=')) {
                const id = value.split('watch?v=')[1].split('&')[0];
                return `https://www.youtube.com/embed/${id}`;
            }
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
.explain-block {
  .video {
    position: relative;
    padding-bottom: 56.36%; /* 16:9 */
    width: 100%;
    height: 0;
    margin-bottom: 50px;

    .video-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .theme {
    .theme-image {
      width: 100%;
    }
  }
}
</style>
